var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"edit-video-sidebar","visible":_vm.isEditVideoSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-edit-video-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"\n        d-flex\n        justify-content-between\n        align-items-center\n        content-sidebar-header\n        px-2\n        py-1\n      "},[_c('h5',{staticClass:"mb-0"},[_vm._v("Edit Video")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('div',{staticClass:"d-flex w-100"},[_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Video Title"}},[_c('b-form-input',{attrs:{"placeholder":"Enter video title..","type":"text"},model:{value:(_vm.videoDetails.title),callback:function ($$v) {_vm.$set(_vm.videoDetails, "title", $$v)},expression:"videoDetails.title"}})],1)],1),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","overflow":"auto"}},[(_vm.videoDetails.video_tumbnail != '')?_c('span',{staticStyle:{"width":"70px","height":"auto","position":"relative"}},[_c('img',{staticStyle:{"width":"200px","height":"120px"},attrs:{"src":_vm.videoDetails.video_tumbnail}})]):_vm._e()]),_c('br'),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.refInputE11.click()}}},[_c('input',{ref:"refInputE11",staticClass:"d-none",attrs:{"type":"file","accept":"image/x-png,image/gif,image/jpeg"},on:{"input":_vm.imageThumbnailUpload}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Upload Thumbnail")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"EditIcon"}})],1),_c('br'),_c('br'),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","overflow":"auto"}},[(
              _vm.videoDetails.video_uri !=
                'https://zape02.s3.ap-southeast-1.amazonaws.com/0.76871584038628660.98656671003321120.19322443436631032.png' &&
              _vm.videoDetails.video_uri !=
                'https://zape02.s3.ap-southeast-1.amazonaws.com/0.5286847033473350.93913359917665160.43434108423423257.gif'
            )?_c('span',{staticStyle:{"width":"200px","height":"auto","position":"relative"}},[_c('b-embed',{attrs:{"type":"video","aspect":"4by3","controls":"","poster":_vm.videoDetails.video_tumbnail}},[_c('source',{attrs:{"src":_vm.videoDetails.video_uri,"type":"video/mp4"}})])],1):_c('span',{staticStyle:{"width":"200px","height":"auto","position":"relative"}},[_c('img',{staticStyle:{"width":"200px","height":"120px"},attrs:{"src":_vm.videoDetails.video_uri,"alt":""}})])]),_c('br'),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.refInputE2.click()}}},[_c('input',{ref:"refInputE2",staticClass:"d-none",attrs:{"type":"file","accept":"video/mp4"},on:{"input":_vm.videoUpload}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Upload Video")]),_c('feather-icon',{staticClass:"d-inline d-sm-none",attrs:{"icon":"EditIcon"}})],1),_c('br'),_c('div',{staticClass:"d-flex mt-2",staticStyle:{"position":"absolute","bottom":"3%","left":"3%"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.submitbutton},on:{"click":hide}},[_vm._v(" Add ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
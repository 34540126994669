<template>
  <div>
    <add-video
      :is-add-video-sidebar-active.sync="isAddVideoSidebarActive"
      @clickAddNewVideoSuccess="geVideosData"
    />
    <edit-video
      :is-edit-video-sidebar-active.sync="isEditVideoSidebarActive"
      :editVideoObj="editVideoObj"
      @clickEditNewVideoSuccess="geVideosData"
    />
    <b-overlay :show="show" rounded="sm">
      <b-card no-body class="padding_class">
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="
                d-flex
                align-items-center
                justify-content-start
                mb-1 mb-md-0
              "
            >
            </b-col>
            <b-col cols="12" md="6" v-if="removeButtons == true">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  @input="filterVideosByTitle"
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                />
                <b-button
                  variant="primary"
                  @click="isAddVideoSidebarActive = true"
                >
                  <span class="text-nowrap">Add Video</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-tabs>
          <b-tab active @click="geVideosData(1)">
            <template #title>
              <span>All Videos</span>
            </template>
            <div :key="sidebarkey">
              <b-row class="match-height">
                <b-col
                  sm="3"
                  md="2"
                  lg="1"
                  style="padding: 10px; position: relative"
                  v-for="videos in table_data"
                  :key="videos._id"
                  v-lazy-container="{
                    selector: 'img',
                    error:
                      'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                    loading:
                      'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                  }"
                >
                  <div class="cont-checkbox">
                    <input type="checkbox" id="myCheckbox-1" />
                    <b-tooltip :target="videos._id + '1'" variant="primary">
                      <div
                        style="width: 200px; height: auto; object-fit: contain"
                      >
                        <b-embed
                          type="video"
                          aspect="4by3"
                          controls
                          :poster="imageTooltipText.videoThumbnail"
                        >
                          <source
                            :src="imageTooltipText.video_uri"
                            type="video/webm"
                          />
                          <source
                            :src="imageTooltipText.video_uri"
                            type="video/x-m4v"
                          />
                          <source
                            :src="imageTooltipText.video_uri"
                            type="video/mp4"
                          />
                          <source
                            :src="imageTooltipText.video_uri"
                            type="video/avi"
                          />
                        </b-embed>
                      </div>

                      <h6 style="color: white">
                        {{ imageTooltipText.title }}
                      </h6>
                      <div class="d-flex align-items-center">
                        <div class="d-block w-100">
                          <feather-icon
                            style="margin-right: 10px"
                            v-if="removeButtons == true"
                            @click="EditVideoFunc(videos)"
                            icon="EditIcon"
                          />
                          <feather-icon
                            v-if="removeButtons == true"
                            @click="deleteVideoFunc(videos._id)"
                            icon="TrashIcon"
                          />
                        </div>
                      </div>
                    </b-tooltip>

                    <label :for="videos._id">
                      <input type="checkbox" id="myCheckbox-1" />

                      <div>
                        <img
                          :id="videos._id + '1'"
                          @mouseover="mouseOver(videos)"
                          :data-src="videos.videoThumbnail"
                        />
                      </div>
                    </label>
                  </div>
                  <div class="d-flex flex-stack flex-wrap">
                    <div class="d-block w-100">
                      {{ videos.title }}
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardTitle,
  BCardSubTitle,
  BTableSimple,
  BThead,
  BTbody,
  BDropdown,
  BDropdownItem,
  BTr,
  BTh,
  BTd,
  BLink,
  BFormGroup,
  BFormInput,
  BCarousel,
  BCarouselSlide,
  VBTooltip,
  BFormCheckbox,
  BBadge,
  BSpinner,
  BOverlay,
  BTooltip,
  BEmbed,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import AddVideo from "./Models/AddVideo.vue";
import EditVideo from "./Models/EditVideo.vue";
import { BTabs, BTab } from "bootstrap-vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    ToastificationContent,
    BSpinner,
    BBadge,
    BOverlay,
    BTooltip,
    AddVideo,
    BCarousel,
    BCarouselSlide,
    BCard,
    BFormGroup,
    BCardText,
    BDropdown,
    BDropdownItem,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTabs,
    BTab,
    BFormCheckbox,
    BButton,
    VBTooltip,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BTbody,
    BImg,
    BFormInput,
    vSelect,
    BEmbed,
    EditVideo,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      breadCrumb: [],
      folderType: "gallery",
      imageStatus: "galleryImg",
      imageTooltipText: "",
      editgalleryid: "",
      resizeData: {},
      tag: [],
      addTag: {
        name: "",
      },
      NotResizeData: {},
      editgalleryimg: "",
      sidebarkey: 0,
      isAddVideoSidebarActive: false,
      isEditVideoSidebarActive: false,
      editVideoObj: {},
      perPage: 5,
      show: false,
      searchQuery: "",
      perPageOptions: [10, 25, 50, 100],
      table_data: [],
      table_: {},
      table_sort: {},
      pageNewData: 0,
      deletegallerydata: {
        img_id: "",
      },
      tabIndexKey: 1,
      MultiSelectedDeteted: [],
      original_table_data: [],
      selectedResizeImage: "",
      showspinner: false,
      resizeIndexstore: "",
      NotAppicableIamge: {},
      searchResize: "",
      removeButtons: false,
      editgalleryName: "",
      allFolder: [],
      editgalleryTag: [],
      rename: {
        id: "",
        name: "",
      },
      selectedtag: [],
      tagImageId: "",
    };
  },
  computed: {
    selectButton() {
      return !(this.MultiSelectedDeteted.length > 0);
    },
    submitbutton() {
      return !(this.addTag.name.length > 0);
    },
  },
  mounted() {
    var newPermission = JSON.parse(localStorage.getItem("permission"));
    for (let index = 0; index < newPermission.permission.length; index++) {
      if (newPermission.permission[index] == "manage_videos") {
        this.removeButtons = true;
        break;
      } else {
        this.removeButtons = false;
      }
    }
  },
  created() {
    this.geVideosData();
  },
  methods: {
    selectAllData() {
      this.selectedtag = [];
    },
    addTagFunc() {
      axios
        .post(process.env.VUE_APP_API_URL + "/gallery-tag/add", this.addTag, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          if (response.data.message == "Tag create successfully") {
            this.alertMessageToast = response.data.message;
            this.tag = response.data.data;
            this.addTag.name = "";
            this.showToast("success");
            this.$root.$emit("bv::hide::modal", "modal-tag-add");
          } else {
            this.addTag.name = "";
            this.alertMessageToast = "Tag not created try again";
            this.showToast("danger");
            this.$root.$emit("bv::hide::modal", "modal-tag-add");
          }
        });
    },
    getAllTag() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-tags/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((res) => {
          for (let index = 0; index < res.data.length; index++) {
            res.data[index]["status"] = false;
          }
          this.tag = res.data;
        });
    },
    moveImages(id) {
      let moveImages = {
        folder_id: id,
        Images: this.MultiSelectedDeteted,
      };
      axios
        .post(
          process.env.VUE_APP_API_URL + "/gallery-images/move-to/folder",
          moveImages,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          if (response.data.message == "Images moved successfully") {
            this.alertMessageToast = response.data.message;
            this.showToast("success");
            this.$root.$emit("bv::hide::modal", "moveFolderModal");
          } else {
            this.alertMessageToast = "Fail to move images";
            this.showToast("danger");
            this.$root.$emit("bv::hide::modal", "moveFolderModal");
          }
        });
    },
    deleteFolder(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do You want to delete folder",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          let file = {
            id: id,
          };
          axios
            .post(
              process.env.VUE_APP_API_URL + "/gallery/delete-folder",
              file,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("_t"),
                },
              }
            )
            .then((response) => {
              if (response.data.message == "Folder deleted successfully") {
                this.getAllFolder();
                this.alertMessageToast = response.data.message;
                this.showToast("success");
              } else {
                this.alertMessageToast = "Folder not created try again";
                this.showToast("danger");
              }
            });
        }
      });
    },
    renameSave() {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/gallery/rename-folder",
          this.rename,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          if (response.data.message == "Folder renamed successfully") {
            this.alertMessageToast = response.data.message;
            this.getAllFolder();
            this.showToast("success");
            this.$root.$emit("bv::hide::modal", "modal-rename");
          } else {
            this.alertMessageToast = "Folder not created try again";
            this.showToast("danger");
            this.$root.$emit("bv::hide::modal", "modal-rename");
          }
        });
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.alertMessageToast,
          variant,
        },
      });
    },
    renameFolder(id, name) {
      this.rename = {
        id: id,
        name: name,
      };
    },
    createfolderSuccess(status) {
      if (status) {
        this.iscreateFolderSidebarActive = false;
        this.getAllFolder();
      } else {
        this.iscreateFolderSidebarActive = false;
        this.getAllFolder();
      }
    },
    getAllFolder() {
      axios
        .get(process.env.VUE_APP_API_URL + "/getfolders/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((res) => {
          this.allFolder = res.data;
        });
    },
    getNotapplicableImages(tabinde) {
      this.show = true;
      this.tabIndexKey = tabinde;
      var idsArr = [];
      for (let i = 0; i < this.tag.length; i++) {
        if (this.tag[i]["status"] == true) {
          idsArr.push(this.tag[i]["_id"]);
        }
      }
      if (idsArr.length > 0) {
        let tagObject = {
          id: idsArr,
          tabId: 4,
        };
        axios
          .post(
            process.env.VUE_APP_API_URL + "/gallery-tag/filter",
            tagObject,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            let dataArr = [];
            for (let i = 0; i < response.data.length; i++) {
              response.data[i]["showcheckbox"] = false;
              dataArr.push(response.data[i]);
            }
            this.table_data = dataArr;
            this.show = false;
          });
      } else {
        this.searchQuery = "";
        this.table_data = [];
        this.pageNewData = 0;
        this.MultiSelectedDeteted = [];
        this.table_ = {};
        axios
          .get(
            process.env.VUE_APP_API_URL + "/zape-cms/all/non-applicable/resize",
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((get_response) => {
            this.table_data = [];
            this.table_ = get_response.data.nonApplicable;
            for (let index = 0; index < 50; index++) {
              if (get_response.data.nonApplicable[index]) {
                this.table_data.push(get_response.data.nonApplicable[index]);
                this.pageNewData = this.pageNewData + 1;
              }
            }
            for (let index = 0; index < this.table_data.length; index++) {
              if (this.table_data[index]) {
                this.table_data[index]["showcheckbox"] = false;
              }
            }
            this.show = false;
          });
      }
    },
    getNonresizeImages(tabinde) {
      this.show = true;
      this.tabIndexKey = tabinde;
      var idsArr = [];
      for (let i = 0; i < this.tag.length; i++) {
        if (this.tag[i]["status"] == true) {
          idsArr.push(this.tag[i]["_id"]);
        }
      }
      if (idsArr.length > 0) {
        let tagObject = {
          id: idsArr,
          tabId: 3,
        };
        axios
          .post(
            process.env.VUE_APP_API_URL + "/gallery-tag/filter",
            tagObject,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            let dataArr = [];
            for (let i = 0; i < response.data.length; i++) {
              response.data[i]["showcheckbox"] = false;
              dataArr.push(response.data[i]);
            }
            this.table_data = dataArr;
            this.show = false;
          });
      } else {
        this.searchQuery = "";
        this.table_data = [];
        this.pageNewData = 0;
        this.MultiSelectedDeteted = [];
        this.table_ = {};
        axios
          .get(process.env.VUE_APP_API_URL + "/zape-cms/all/non-resize", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          })
          .then((get_response) => {
            this.table_ = get_response.data.nonResize;
            this.table_data = [];
            for (let index = 0; index < 50; index++) {
              if (get_response.data.nonResize[index]) {
                this.table_data.push(get_response.data.nonResize[index]);
                this.pageNewData = this.pageNewData + 1;
              }
            }
            for (let index = 0; index < this.table_data.length; index++) {
              if (this.table_data[index]) {
                this.table_data[index]["showcheckbox"] = false;
              }
            }
            this.show = false;
          });
      }
    },
    getresizeImages(tabinde) {
      this.show = true;
      this.tabIndexKey = tabinde;
      var idsArr = [];
      for (let i = 0; i < this.tag.length; i++) {
        if (this.tag[i]["status"] == true) {
          idsArr.push(this.tag[i]["_id"]);
        }
      }
      if (idsArr.length > 0) {
        let tagObject = {
          id: idsArr,
          tabId: 2,
        };
        axios
          .post(
            process.env.VUE_APP_API_URL + "/gallery-tag/filter",
            tagObject,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            let dataArr = [];
            for (let i = 0; i < response.data.length; i++) {
              response.data[i]["showcheckbox"] = false;
              dataArr.push(response.data[i]);
            }
            this.table_data = dataArr;
            this.show = false;
          });
      } else {
        this.searchQuery = "";
        this.table_data = [];
        this.pageNewData = 0;
        this.MultiSelectedDeteted = [];
        this.table_ = {};
        axios
          .get(process.env.VUE_APP_API_URL + "/zape-cms/all/resize-images", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          })
          .then((get_response) => {
            this.table_data = [];
            this.table_ = get_response.data.Resize;
            for (let index = 0; index < 50; index++) {
              if (get_response.data.Resize[index]) {
                this.pageNewData = this.pageNewData + 1;
                this.table_data.push(get_response.data.Resize[index]);
              }
            }
            for (let index = 0; index < this.table_data.length; index++) {
              if (this.table_data[index]) {
                this.table_data[index]["showcheckbox"] = false;
              }
            }
            this.show = false;
          });
      }
    },
    closejsonmodel() {
      this.$root.$emit("bv::hide::modal", "modal-xl");
    },
    checkboxdata(i, index) {
      this.table_data[index]["opacityChange"] =
        !this.table_data[index]["opacityChange"];
      this.MultiSelectedDeteted = [];
      for (let j = 0; j < this.table_data.length; j++) {
        if (this.table_data[j]["opacityChange"] == true) {
          this.MultiSelectedDeteted.push(this.table_data[j]["_id"]);
        }
      }
    },
    forcRender() {
      this.sidebarkey += 1;
    },
    selectImages() {
      for (let index = 0; index < this.table_data.length; index++) {
        if (this.table_data[index]["showcheckbox"]) {
          this.table_data[index]["showcheckbox"] = false;
        } else {
          this.table_data[index]["showcheckbox"] = true;
        }
        this.table_data[index]["opacityChange"] = false;
      }
      this.forcRender();
    },
    filterVideosByTitle() {
      this.show = true;

      if (this.searchQuery.trim().length > 0) {
        let finaldata = this.table_.filter((item) => {
          if (item.title) {
            return this.searchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.title.toLowerCase().includes(v));
          }
        });
        this.table_data = finaldata;
        this.show = false;
      } else {
        this.table_data = this.table_;
        this.show = false;
      }
    },
    EditVideoFunc(data) {
      this.editVideoObj = data;
      this.isEditVideoSidebarActive = true;
    },
    deleteallimages() {
      axios
        .get(process.env.VUE_APP_API_URL + "/delete_Gallery_Image")
        .then((get_response) => {});
    },
    preImage() {
      if (this.pageNewData != 0) {
        this.table_data = [];
        for (let index = 50; index > 0; index--) {
          this.pageNewData = this.pageNewData - 1;
          if (this.table_[this.pageNewData]) {
            this.table_[this.pageNewData]["showcheckbox"] = false;
            this.table_data.push(this.table_[this.pageNewData]);
          }
        }
      }
    },
    nextImage() {
      this.table_data = [];
      if (this.table_.length != 0) {
        for (let index = 0; index < 50; index++) {
          this.pageNewData = this.pageNewData + 1;
          if (this.table_[this.pageNewData]) {
            this.table_[this.pageNewData]["showcheckbox"] = false;
            this.table_data.push(this.table_[this.pageNewData]);
          }
        }
      }
    },
    Non_ResizeImage() {
      axios
        .get(process.env.VUE_APP_API_URL + "/zape-cms/resize/count", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          this.NotResizeData = get_response.data.nonResize;
          this.resizeData = get_response.data.Resize;
          this.NotAppicableIamge = get_response.data.nonApplicable;
        });
    },
    ImageResize() {
      if (this.searchResize.trim().length > 0) {
        return this.resizeData.filter((item) => {
          if (item.image_name) {
            return this.searchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.image_name.toLowerCase().includes(v));
          }
        });
      } else {
        return this.resizeData;
      }
    },
    recent_image_used_in_gallery: function (tabinde) {
      this.show = true;
      this.tabIndexKey = tabinde;
      var idsArr = [];
      for (let i = 0; i < this.tag.length; i++) {
        if (this.tag[i]["status"] == true) {
          idsArr.push(this.tag[i]["_id"]);
        }
      }
      if (idsArr.length > 0) {
        let tagObject = {
          id: idsArr,
          tabId: 1,
        };
        axios
          .post(
            process.env.VUE_APP_API_URL + "/gallery-tag/filter",
            tagObject,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            let dataArr = [];
            for (let i = 0; i < response.data.length; i++) {
              response.data[i]["showcheckbox"] = false;
              dataArr.push(response.data[i]);
            }
            this.table_data = dataArr;
            this.show = false;
          });
      } else {
        this.searchQuery = "";
        this.table_data = [];
        this.pageNewData = 0;
        this.MultiSelectedDeteted = [];
        this.table_ = {};
        axios
          .get(process.env.VUE_APP_API_URL + "/zape-cms/recent-used/images", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          })
          .then((get_response) => {
            this.table_data = [];
            this.table_ = get_response.data;
            for (let index = 0; index < 50; index++) {
              if (get_response.data[index]) {
                this.table_data.push(get_response.data[index]);
                this.pageNewData = this.pageNewData + 1;
              }
            }
            for (let index = 0; index < this.table_data.length; index++) {
              if (this.table_data[index]) {
                this.table_data[index]["showcheckbox"] = false;
              }
            }
            for (let index = 0; index < this.table_data.length; index++) {
              if (this.table_data[index]) {
                this.table_data[index]["opacityChange"] = false;
              }
            }
            this.show = false;
          });
      }
    },
    recent_image_add_in_gallery: function (tabinde) {
      this.show = true;
      this.tabIndexKey = tabinde;
      var idsArr = [];
      for (let i = 0; i < this.tag.length; i++) {
        if (this.tag[i]["status"] == true) {
          idsArr.push(this.tag[i]["_id"]);
        }
      }
      if (idsArr.length > 0) {
        let tagObject = {
          id: idsArr,
          tabId: 1,
        };
        axios
          .post(
            process.env.VUE_APP_API_URL + "/gallery-tag/filter",
            tagObject,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            let dataArr = [];
            for (let i = 0; i < response.data.length; i++) {
              response.data[i]["showcheckbox"] = false;
              dataArr.push(response.data[i]);
            }
            this.table_data = dataArr;
            this.show = false;
          });
      } else {
        this.searchQuery = "";
        this.table_data = [];
        this.pageNewData = 0;
        this.MultiSelectedDeteted = [];
        this.table_ = {};
        axios
          .get(process.env.VUE_APP_API_URL + "/zape-cms/recent-add/images", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          })
          .then((get_response) => {
            this.table_data = [];
            this.table_ = get_response.data;
            for (let index = 0; index < 50; index++) {
              if (get_response.data[index]) {
                this.table_data.push(get_response.data[index]);
                this.pageNewData = this.pageNewData + 1;
              }
            }
            for (let index = 0; index < this.table_data.length; index++) {
              if (this.table_data[index]) {
                this.table_data[index]["showcheckbox"] = false;
              }
            }
            for (let index = 0; index < this.table_data.length; index++) {
              if (this.table_data[index]) {
                this.table_data[index]["opacityChange"] = false;
              }
            }
            this.show = false;
          });
      }
    },
    geVideosData: function (tabinde) {
      this.show = true;
      axios
        .get(process.env.VUE_APP_API_URL + "/zape/video/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          this.show = false;
          this.table_data = response.data.data;
          this.table_ = response.data.data;
        });
    },
    clickedAddGalleryData: function (gallery_data) {
      gallery_data["status"] = "gallery img";
      axios
        .post(process.env.VUE_APP_API_URL + "/addgallerydata", gallery_data)
        .then((response) => {
          if (this.tabIndexKey == 1) {
            this.geVideosData(1);
          } else if (this.tabIndexKey == 2) {
            this.recent_image_add_in_gallery();
          } else if (this.tabIndexKey == 3) {
            this.recent_image_used_in_gallery(3);
          } else if (this.tabIndexKey == 4) {
            this.getresizeImages(4);
          } else if (this.tabIndexKey == 5) {
            this.getNonresizeImages(5);
          } else if (this.tabIndexKey == 6) {
            this.getNotapplicableImages(6);
          }
        });
    },
    clickeditimagegallerydata: function (edit_gallery_data) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/edit_gallery_data",
          edit_gallery_data
        )
        .then((response) => {
          if (this.tabIndexKey == 1) {
            this.geVideosData(1);
          } else if (this.tabIndexKey == 2) {
            this.recent_image_add_in_gallery(2);
          } else if (this.tabIndexKey == 3) {
            this.recent_image_used_in_gallery(3);
          } else if (this.tabIndexKey == 4) {
            this.getresizeImages(4);
          } else if (this.tabIndexKey == 5) {
            this.getNonresizeImages(5);
          } else if (this.tabIndexKey == 6) {
            this.getNotapplicableImages(6);
          }
        });
    },
    deletegalleryimg: function (delete_gallery_data) {
      if (typeof delete_gallery_data == "string") {
        this.MultiSelectedDeteted = [];
        this.MultiSelectedDeteted.push(delete_gallery_data);
        this.deletegallerydata.img_id = this.MultiSelectedDeteted;
      } else {
        this.deletegallerydata.img_id = this.MultiSelectedDeteted;
      }
      axios
        .post(
          process.env.VUE_APP_API_URL + "/delete_gallery_data",
          this.deletegallerydata
        )
        .then((response) => {
          if (response.data.message == "Image deleted successfully") {
            this.alertMessageToast = response.data.message;
            this.showToast("success");
            this.MultiSelectedDeteted = [];
            if (this.tabIndexKey == 1) {
              this.geVideosData(1);
            } else if (this.tabIndexKey == 2) {
              this.recent_image_add_in_gallery();
            } else if (this.tabIndexKey == 3) {
              this.recent_image_used_in_gallery(3);
            } else if (this.tabIndexKey == 4) {
              this.getresizeImages(4);
            } else if (this.tabIndexKey == 5) {
              this.getNonresizeImages(5);
            } else if (this.tabIndexKey == 6) {
              this.getNotapplicableImages(6);
            }
          } else {
            this.alertMessageToast = "Images not deleted successfully";
            this.showToast("danger");
          }
        });
    },
    deleteVideoFunc(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do You want to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(process.env.VUE_APP_API_URL + "/zape/video/delete/" + id, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            })
            .then((response) => {
              // console.log("response.data", response.data);
              if (response.data.status) {
                this.alertMessageToast = response.data.message;
                this.showToast("success");
                this.geVideosData();
              } else {
                this.alertMessageToast =
                  "There are some problem with the video try again.";
                this.showToast("danger");
              }
            });
        }
      });
    },

    mouseOver(a) {
      this.imageTooltipText = a;
    },
    editgalleryimage(gallery_id, image_name, gallery_img, tagId) {
      this.isAddEditGallerySidebarActive = true;
      this.editgalleryid = gallery_id;
      if (image_name) {
        this.editgalleryName = image_name;
      }
      if (tagId != undefined) {
        this.editgalleryTag = tagId;
      } else {
        this.editgalleryTag = [];
      }
      this.editgalleryimg = gallery_img;
    },
  },
};
</script>
  <style>
.zoom:hover {
  transform: scale(5) !important;
}
.box_container .box_inner .dropdown button {
  padding: 0 !important;
}
.box_container .box_inner {
  display: flex;
  justify-content: flex-start;
}
.box_container .dropdown-menu {
  min-width: 8rem;
  margin-top: -17px !important;
}
.box_container .dropdown a.dropdown-item {
  padding: 0px 8px;
}
.padding_class {
  padding: 1rem;
}

.cont-checkbox:first-of-type {
  margin-bottom: 0.75rem;
  margin-right: 0.75rem;
}

.cont-checkbox:active {
  transform: scale(0.9);
}

.cont-checkbox input {
  display: none;
}

.cont-checkbox input:checked + label {
  box-shadow: 0 0 0 3px var(--background);
}

.cont-checkbox.checkedClass img {
  opacity: 0.6;
  border: 5px solid black;
}

.cont-checkbox input:checked + label img {
  -webkit-filter: none;
  filter: none;
}

.cont-checkbox input:checked + label .cover-checkbox {
  opacity: 1;
  transform: scale(1);
}

.cont-checkbox input:checked + label .cover-checkbox svg {
  stroke-dashoffset: 0;
}

.cont-checkbox label {
  display: inline-block;
  cursor: pointer;
  border-radius: var(--border-radius);
  width: 100%;
  height: 100%;
  position: relative;
}

.cont-checkbox label img {
  width: 100%;
  height: 100%;
  width: 50px;
  height: 50px;
  border: 2px solid #faf9f6;
  border-radius: 10px;
}

.cont-checkbox label .cover-checkbox {
  position: absolute;
  right: 5px;
  top: 3px;
  z-index: 1;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--box-shadow);
  border: 2px solid #fff;
  opacity: 0;
  transform: scale(0);
}

.cont-checkbox label .cover-checkbox svg {
  width: 13px;
  height: 11px;
  display: inline-block;
  vertical-align: top;
  fill: none;
  margin: 5px 0 0 3px;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
}

.cont-checkbox label .info {
  text-align: center;
  margin-top: 0.2rem;
  font-weight: 600;
  font-size: 0.8rem;
}

.cont-checkbox {
  transition: 1s !important;
}
.cont-checkbox .card {
  opacity: 0 !important;
  position: absolute;
  left: 50%;
  top: 0;
  width: 100px;
  height: 100px;
  margin-left: -50px;
  transition: 1s !important;
  z-index: 1;
}
.cont-checkbox:hover .card {
  opacity: 1 !important;
  z-index: 9000;
  width: 150px;
  height: 150px;
  margin-left: -75px;
  transition: 0.3s !important;
}
.cardImg img {
  width: 150px;
  height: 150px;
}
</style>
